$('a:not([href^="tel:"])').on('click tap', function(e) {

  var ref = location.href;
  var url = $(this).attr('href');
  var window_scroll_num = $('body').css('top');
  window_scroll_num = window_scroll_num.replace(/[^0-9]/g, '');

  if( ref.indexOf(url.replace(/#.*$/, '')) != -1 && url.indexOf('#') != -1 && url != '#' ) {

    var speed = 300;
    var href= url.match(/#.*$/);
    var target = href[0];
    var position = $(target).offset().top - $('.js-header').outerHeight() + Number(window_scroll_num);
    $('body, html').animate({ scrollTop: position }, speed, 'swing');
    return false;
  }
});

$('.js-menu-toggle').on('click tap', function() {
  var status = $('body').attr('data-menu');
  var scrollTop = $(window).scrollTop();
  var window_width = window.innerWidth;

  if( status == 'active' ) {

    $('body').attr('data-menu', '');

    $('.js-hamburger').attr('data-under-menu', '');

    if(window_width > 1024) {
      var window_scroll_num = $('body').css('top');

      window_scroll_num = window_scroll_num.replace(/[^0-9]/g, '');

      $('body').css({'top':0});

      window.scrollTo(0,Number(window_scroll_num));
    }

  } else {

    $('body').attr('data-menu', 'active');

    if(window_width > 1024) {

      $('body').css({'top':-Math.floor(scrollTop)});

    }

    setTimeout(function() {
      $('.js-hamburger').attr('data-under-menu', 'active');
    }, 300);

  }
});

//ハンバーガー内の aタグクリック時
$('.js-hamburger a').on('click tap', function() {
  var window_width = window.innerWidth;

  $('body').attr('data-menu', '');

  $('.js-hamburger').attr('data-under-menu', '');

  if(window_width > 1024) {
    var window_scroll_num = $('body').css('top');

    window_scroll_num = window_scroll_num.replace(/[^0-9]/g, '');

    $('body').css({'top':0});

    window.scrollTo(0,Number(window_scroll_num));
  }

});


$(window).on('load scroll touchmove', function() {

  var page_scrollTop = $(window).scrollTop();

  if( $('.js-pagetop')[0] ) {

    if (page_scrollTop > 1) {
      $('.js-pagetop').addClass('is-active');
    }
    else {
      $('.js-pagetop').removeClass('is-active');
    }
  }

});

$(window).on('load',function(){

  if( location.hash && $(location.hash)[0] ) {

    var hash = location.hash;

    setTimeout(function() {
      $('body, html').animate({ scrollTop: $(hash).offset().top - $('.js-header').outerHeight() }, 0, 'linear');
    }, 300 );

  }

  $('.js-pagetop-btn').on('click tap', function() {
    $('body, html').animate({
      scrollTop: 0
    }, 300, 'swing');
  });

  loadFile('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,400;0,600;1,500&display=swap', 'css');
  loadFile('https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;500;700&display=swap', 'css');
  loadFile('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.1.1/css/all.min.css', 'css');

  $('body').attr('data-status', 'loaded');
})


// Lazy Load
$(window).on('load resize scroll', function() {

  // Background
  $('[data-lazy-background]').each(function() {
    if ($(window).scrollTop() + $(window).height() > $(this).offset().top) {
      $(this).css('background-image', 'url('+$(this).data('lazy-background')+')');
      $(this).removeAttr('data-lazy-background');
    }
  });

  // img
  $('[data-lazy-img]').each(function() {
    if ($(window).scrollTop() + $(window).height() > $(this).offset().top) {
      $(this).attr('src', $(this).data('lazy-img'));
      $(this).removeAttr('data-lazy-img');
    }
  });

});

// スクロールしたらだす
$(window).on('load resize scroll', function() {

  $('.js-scroll-pop').each(function() {
    if ($(window).scrollTop() + $(window).height() > $(this).offset().top + 100) {
      $(this).addClass('is-active');
    }
  });

});


//トップページ写真位置調整
$(window).on('load',function() {
  let window_width = window.innerWidth;

  if(window_width > 1023) {
    $('.a_block').each(function() {
      let height = $(this).find('[data-index="1"]').outerHeight();
      $(this).find('[data-index="2"]').css('margin-top', -height / 2);
    });

    $('.c_block').each(function() {
      let height = $(this).find('[data-index="3"]').outerHeight();
      $(this).find('[data-index="4"]').css('margin-top', -height / 4);
    })
  }

});

//vimeoからサムネを引っ張る
$(function(){
  let page = $('body').attr('class');

    $('.is-movie').each(function() {
      let $this = $(this);

      let thumb_check = $this.find('.js-thumb').attr('src');

      // url取得
      let movie_url = $this.attr('data-movie');

      if(movie_url) {
        // JSON取得
        $.getJSON(
          'https://vimeo.com/api/oembed.json?url=' + movie_url + '&width=640&height=360',
          null,
          function(data) {
            if(!thumb_check) {
              $this.find('.js-thumb').attr('src',data.thumbnail_url);

              if(page !== 'is-home') {
                $this.find('.js-thumb-bg').css('background-image','url("' + data.thumbnail_url + '"');
              }
            }
          }
        );
      }

    });
})

/**
 * CMS Page - ajax
 */
$(window).on('scroll', function() {
  if($('.js-cmsmore')[0]) {
    var elm = $('.js-cmsmore');
    if ($(window).scrollTop() + $(window).height() > elm.offset().top) {
      cmsMore(elm);
    }
  }
});


/**
 * CMS Page - ajax
 */
function cmsMore(button) {
  button.removeClass('js-cmsmore');
  button.addClass('cmsloading').find('.c-more__btn-txt').html('<img src="' + append_post_params.assets_path + 'img/common/loader.svg" width="40" height="40" alt="">');

  var data = {
    'action': 'loadmore',
    'per_page': append_post_params.per_page,
    'paged': append_post_params.paged,
    'tax_query': append_post_params.tax_query,
  };

  $.ajax({
    url: append_post_params.ajaxurl,
    data: data,
    type: 'POST',
  })
  .then(
    function(data) {
      console.log(data.paged + ' / ' + data.max_num_pages);
      button.prev().append(data.posts);
      button.removeClass('cmsloading').find('.c-more__btn-txt').text('MORE');
      append_post_params.paged++;

      if(append_post_params.paged >= data.max_num_pages) {
        button.remove();
      }
      else {
        button.addClass('js-cmsmore');
      }

      $('.is-movie').each(function() {
        var $this = $(this);
        var thumb_check = $this.find('.js-thumb').attr('src');

        if(!thumb_check) {
          // url取得
          var movie_url = $this.attr('data-movie');

          if(movie_url) {
            // JSON取得
            $.getJSON(
              'https://vimeo.com/api/oembed.json?url=' + movie_url + '&width=640&height=360',
              null,
              function(data) {
                  $this.find('.js-thumb').attr('src',data.thumbnail_url);
                  $this.find('.js-thumb-bg').css('background-image','url("' + data.thumbnail_url + '"');

              }
            );
          }
        }
      });
    },
    //通信エラー時
    function() {
      button.remove();
    }
  );
}

/* mwwpform エラーinputの背景色 */
$(window).on('load',function() {
  $('.error').prev('input').each(function() {
    $(this).addClass('is-error');
  })
})



function loadFile(src, type) {

  if( type == 'js' ) {
    $('body').append('<script src="'+src+'"></script>');
  }

  else if( type == 'css' ) {
    $('body').append('<link rel="stylesheet" href="'+src+'">');
  }

  else {
    return false;
  }

}
